@import "../../variables.scss";

section.home {
    background: url(../../assets/bg.jpg) no-repeat center center / cover;
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    h1 {
        color: #fff;
        position: absolute;
        left: 20px;
        top: 20px;
        font-weight: 300;
        font-size: 18px;
    }

    .itens {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .bts {
        position: absolute;
        left: 0;
        top: calc(50% - 31px);
        width: 100%;
        padding: 0 30px;

        .bt {
            color: #fff;
            min-width: auto;
            min-height: auto;

            &.inativo {
                opacity: 0.3;
                pointer-events: none;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }
    }

    .bt-list {
        position: fixed;
        top: 30px;
        right: 30px;
        color: #fff;

        svg {
            width: 35px;
            height: 35px;
        }
    }
}

@media (max-width: 768px) {
    section.home h1 {
        font-size: 12px;
        top: 15px;
        left: 15px;
        font-weight: 600;
    }
}