@import "../../variables";

.item-fase-lua {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include easeOut();

    &.entra {
        opacity: 1;

        &.passou {
            opacity: 0.2;
        }

        .terra {
            right: calc(50% + 170px);
        }

        h5,
        h4,
        h3,
        img.lua {
            right: 0;
        }

        h5 {
            transition-delay: 100ms;
        }

        h4 {
            transition-delay: 300ms;
        }

        h3 {
            transition-delay: 500ms;
        }

        img.lua {
            transition-delay: 700ms;
        }

        .terra {
            transition-delay: 900ms;
        }
    }

    &.sai {
        opacity: 0;
        transition-duration: 1500ms;

        h5,
        h4,
        h3,
        img.lua,
        .terra {
            right: 150vw;
            transition-duration: 1500ms;
        }
    }

    h5 {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        margin: 0;
        position: relative;
        @include expoOut();
    }

    h4 {
        color: $salmao;
        font-size: 24px;
        border-bottom: solid 1px $azul;
        //padding-bottom: 3px;
        margin: 5px 0;
        display: inline-block;
        position: relative;
        @include expoOut();
    }

    h3 {
        color: $azul;
        font-size: 22px;
        font-weight: 400;
        margin: 0;
        position: relative;
        @include expoOut();
    }

    img.lua {
        height: 70vh;
        position: relative;
        margin-top: -40px;
        @include expoOut();
    }

    .terra {
        position: absolute;
        right: calc(50% + 170px);
        top: calc(50% + 170px);
        margin-top: -40px;
        @include expoOut();

        i {
            display: block;
            width: 200px;
            height: 1px;
            background: $azul;
            transform: translateX(130px) translateY(-10px) rotate(-45deg);
        }

        h6 {
            color: #fff;
            position: absolute;
            font-size: 12px;
            font-weight: 300;
            top: 15%;
            left: 85%;
            width: 100px;

            strong {
                display: block;
            }
        }

        img {
            height: 30vh;
        }
    }
}

@media (max-width: 768px){
    .item-fase-lua img.lua {
        max-width: 100%;
        height: auto;
        margin-top: 0;
        top: calc(50% - 42vh);
    }

    .item-fase-lua.entra .terra {
        right: 50%;
    }

    .item-fase-lua .terra i {
        transform: translateX(65px) translateY(-50px) rotate(-65deg);
    }

    section.home .bt-list {
        top: 15px;
        right: 15px;
    }

    section.home .bts {
        padding: 0;
    }
}