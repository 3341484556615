@import "variables.scss";

body {
    color: #454242;
    font-family: "Roboto", sans-serif;
    margin: 0;
    font-size: 12px;

    >iframe {
        display: none;
    }
}

* {
    outline: none;
    box-sizing: border-box;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-wr {
    flex-wrap: wrap;
}

.flex-ch {
    justify-content: center;
}

.flex-ri {
    justify-content: flex-end;
}

.flex-bv {
    align-items: flex-end;
}

.flex-le {
    justify-content: flex-start;
}

.flex-sp {
    justify-content: space-between;
}

.flex-sa {
    justify-content: last-baseline;
    padding-top: 50px;
}

.flex-cv {
    align-items: center;
}

.page {
    padding: 20px 0;
}

.MuiSnackbarContent-root {

    .MuiSnackbarContent-message {
        font-size: 12px;
    }

    .MuiButtonBase-root {
        font-size: 12px;
        color: #fff;
    }
}

.MuiModal-root {
    justify-content: center;
    display: flex;
    align-items: center;

    .MuiBox-root {
        background: #fff;
        padding: 15px;
        border-radius: 7px;

        ul.itens {
            width: 100%;
            max-height: 70vh;
            overflow-y: auto;
            padding-right: 15px;

            li {
                border-bottom: solid 1px $azul;

                &.super {
                    background: $salmao;
                }

                &.inativo {
                    pointer-events: none;
                    background: $azul;
                    color: #fff;
                }

                &.passou {
                    opacity: 0.3;
                    background: rgba($color: red, $alpha: 0.3);
                }

                &.proxima {
                    position: relative;

                    &::before {
                        content: '* próxima lua';
                        font-size: 10px;
                        position: absolute;
                        bottom: 2px;
                        left: 45px;
                        background: $azul;
                        border-radius: 50px;
                        padding: 0 5px;
                    }
                }

                &:last-child {
                    border: none;
                }

                .bt {
                    width: 100%;
                    justify-content: flex-start;
                    font-size: 14px;
                    color: #333;

                    span.flex {
                        width: 100%;

                        span {
                            margin-right: 20px;
                            display: block;
                            width: 200px;
                            text-align: center;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                margin: 0;
                                text-align: right;
                            }
                        }
                    }

                    img {
                        width: 35px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .MuiModal-root .MuiBox-root ul.itens {
        max-width: calc(100vw - 60px);
    }
}